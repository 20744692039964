import React, { memo, useState, useContext } from 'react'
import getConfig from 'next/config'
import styled from 'styled-components'
import { font, palette } from '@zcool/theme'
import cookie from 'js-cookie'
import { GlobalContext } from 'shared/context'
import { canUseDOM } from 'helpers/util'

const { publicRuntimeConfig } = getConfig()

const SwitchLang = styled.div`
  width: 72px;
  height: 24px;
  margin-bottom: 24px;

  .lang__item {
    box-sizing: border-box;
    display: inline-block;
    width: 50%;
    height: 100%;
    border: 1px solid ${palette.gray};
    text-align: center;
    background-color: #6f6f6f;
    cursor: pointer;

    &:first-child {
      font-size: 12px;
      line-height: 24px;
      border-right: 0;
      border-radius: 12px 0 0 12px;
      font-weight: ${font.weight.medium};
      padding-left: 4px;
      padding-bottom: 1px;
    }

    &:last-child {
      font-size: 14px;
      line-height: 22px;
      font-weight: ${font.weight.normal};
      border-radius: 0 12px 12px 0;
      padding-right: 4px;
    }

    &[data-active='true'] {
      color: ${palette.white};
      background-color: transparent;
    }
  }
`

export const localeArr = [
  { text: '中', value: 'zh' },
  { text: 'EN', value: 'en' },
]

export default memo(function Language() {
  let prevLocale = null

  if (canUseDOM()) {
    prevLocale = cookie.get('locale')
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const global = useContext(GlobalContext)
    prevLocale = global.locale
  }

  const [locale, setLocale] = useState(prevLocale || localeArr[0].value)

  function handleClick(e: React.MouseEvent<HTMLSpanElement>) {
    const value = e.currentTarget.getAttribute('data-value')

    // 重复设置
    if (value === locale) {
      return
    }

    setLocale(value)
    // 设置用户的语言偏好
    cookie.set('locale', value, {
      expires: 365,
      domain: publicRuntimeConfig.COOKIE_DOMAIN,
    })

    window.location.reload()
  }

  return (
    <SwitchLang>
      {localeArr.map((item) => (
        <span
          className="lang__item"
          key={item.value}
          data-value={item.value}
          data-active={locale === item.value}
          onClick={handleClick}
        >
          {item.text}
        </span>
      ))}
    </SwitchLang>
  )
})
