import React, { memo, useState, useContext } from 'react'
import getConfig from 'next/config'
import cookie from 'js-cookie'
import Dropdown from '@zcool/dropdown'
import { List, ListItem } from '@zcool/list'
import { GlobalContext } from 'shared/context'
import { canUseDOM } from 'helpers/util'

const { publicRuntimeConfig } = getConfig()

export const locales = [
  { text: '中文', value: 'zh' },
  { text: 'English', value: 'en' },
]

export default memo(function Language() {
  let prevLocale = null

  if (canUseDOM()) {
    prevLocale = cookie.get('locale')
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const global = useContext(GlobalContext)
    prevLocale = global.locale
  }

  const [locale, setLocale] = useState(prevLocale || locales[0].value)

  function handleClick(e: React.MouseEvent<HTMLSpanElement>) {
    const value = e.currentTarget.getAttribute('data-value')

    // 重复设置
    if (value === locale) {
      return
    }

    setLocale(value)
    // 设置用户的语言偏好
    cookie.set('locale', value, {
      expires: 365,
      domain: publicRuntimeConfig.COOKIE_DOMAIN,
    })

    window.location.reload()
  }

  return (
    <Dropdown text={locales.find((l) => l.value === locale).text} className="language" placement="bottom-end">
      <List>
        {locales.map((item) => (
          <ListItem key={item.value} data-value={item.value} selected={locale === item.value} onClick={handleClick}>
            {item.text}
          </ListItem>
        ))}
      </List>
    </Dropdown>
  )
})
