import React, { useState, useEffect, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import styled, { css } from 'styled-components'
import qs from 'qs'
import Tooltip from '@zcool/tooltip'
import { palette, spacing } from '@zcool/theme'
import { zIndex } from '@zcool/util'
import Icon from 'components/ui/icon'
import { WithEventCheckBox } from 'components/actions'
import { loadImage, getCroppedImg, noop, spliceWebPImageUrl } from 'helpers/util'
import { ContentType } from 'helpers/enums'
import { CONTENT_TYPES } from 'shared/constants'
import { GlobalContext, CreativeContext } from 'shared/context'

export interface ImageProps {
  src: string
  srcTx?: string
  index?: number
  className?: string
  alt?: string
  tag?: string
  tagSize?: number
  mask?: React.ReactNode
  id?: string
  href?: string
  lazyload?: boolean
  hasTitle?: boolean
  contentType?: ContentType
  describe?: React.ReactNode
  position?: 'relative' | 'static'
  actions?: Array<React.ReactNode>
  actionsDisplay?: 'hover' | 'always'
  actionsSize?: 'small' | 'middle' | 'large'
  actionsBackground?: 'black' | 'white'
  actionsPlacement?: 'bottom' | 'top'
  style?: React.CSSProperties
  /** 封面是否下架 */
  isCoverImage?: number | string
  margin?: string
  /** 是否hover显示checkbox */
  hoverShowCheckbox?: boolean
  onLoad?: () => void
  onClick?: (id?: string) => void
  onCheckChange?: (id: string, checked: boolean, index: number, contentType?: number) => void
  checked?: boolean
  checkedId?: string
  searchStatistic?: PLUS.SearchStatistic
  tooltip?: {
    title: React.ReactNode
    placement?: 'top' | 'right' | 'bottom' | 'left'
    overlayStyle?: React.CSSProperties
    boundariesElement?: Element | 'scrollParent' | 'viewport' | 'window'
  }
  target?: string
  showId?: string
  isAigc?: boolean
}

const ImageContainer = styled.figure<{ isCoverImage: number | string }>`
  overflow: hidden;
  width: 100%;
  height: 100%;
  ${(props) =>
    props.isCoverImage !== 1
      ? css`
          background-image: url('/placeholder.svg');
          background: #eef1f3;
        `
      : css`
          background: ${palette.white};
        `}
  background-position: center;
  background-repeat: no-repeat;

  .check-item {
    visibility: hidden;
    width: 24px;
    height: 24px;
    position: absolute;
    right: ${spacing.xs}px !important;
    top: 2px !important;
  }

  &.no_cover {
    position: relative;
    left: 95px;
    top: -50px;
    font-size: 14px;
    color: ${palette.gray};
  }

  &:hover {
    .image__actions {
      display: flex !important;
    }
  }

  &.hover-show-checkbox {
    &.checked,
    &:hover {
      .check-item {
        visibility: visible;
      }
    }
  }

  .image__actions {
    position: absolute;
    z-index: ${zIndex.active};

    /* 图片详情页 */
    &.top {
      top: ${spacing.md}px;
      right: ${spacing.md}px;

      .image__action + .image__action {
        margin-left: ${spacing.sm}px;
      }

      svg {
        box-sizing: content-box;
        padding: 4px;
      }
    }

    /* 图片搜索页 (创意/编辑) */
    &.bottom {
      width: 100%;
      bottom: 0;
      left: 0;

      .icon-group {
        position: absolute;
        right: ${spacing.xs}px;
        bottom: ${spacing.xs}px;
      }

      .image__action + .image__action {
        margin-left: ${spacing.xs}px;
      }
    }

    &.always {
      display: initial;
    }

    &.hover {
      display: none;
    }

    .image__action {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;

      &.white {
        background: rgba(255, 255, 255, 0.9);
      }

      &.black {
        background: rgba(51, 51, 51, 0.5);
      }

      &.small {
        width: 24px;
        height: 24px;
      }

      &.middle {
        width: 32px;
        height: 32px;
      }

      &.large {
        width: 36px;
        height: 36px;
      }

      .tooltip__reference {
        line-height: 0;
        position: relative;
      }
    }
  }

  &.relative {
    position: relative;
  }

  &.static {
    position: static;
  }

  > a:first-child {
    width: 100%;
    display: inline-block;
  }

  img {
    width: 100%;
    height: auto;

    &.default {
      max-width: 100%;
      width: auto;
      height: auto;
    }
  }

  .icon {
    position: absolute;
    left: 0;
    top: 0;
  }

  .icon_buy {
    top: -14px;
  }

  .rex {
    top: -7px;
  }

  .only-plus,
  .essential,
  .choice {
    width: 68px;
    height: 25px;
  }

  .is-single {
    width: 63px;
    height: 63px;
  }

  .aiTag {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 66;

    &:hover {
      width: 34px;
      height: 22px;
      background: #000c;
    }
  }
`

const FontBg = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url('/font-cover.svg');
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;

  &.static {
    height: 75%;
    top: 12.5%;
  }
`

const Image = React.memo(function HfImage({
  index,
  tag,
  tagSize = 42,
  src,
  srcTx,
  className,
  id,
  alt = '',
  actions,
  href,
  contentType = 1,
  hoverShowCheckbox = false,
  onClick = noop,
  onCheckChange = noop,
  onLoad,
  checked,
  checkedId,
  hasTitle,
  describe,
  lazyload = true,
  position = 'relative',
  actionsDisplay = 'hover',
  actionsSize = 'small',
  actionsBackground = 'black',
  actionsPlacement = 'bottom',
  tooltip,
  target = '_blank',
  searchStatistic,
  isCoverImage,
  children,
  showId,
  isAigc,
  ...rest
}: ImageProps & { children?: React.ReactChild }) {
  // invariant(!!src, 'image should have a src value')
  const { isWebPSupported } = useContext(GlobalContext)

  const { image } = useContext(CreativeContext) || { image: { id: '' } }

  const isFormShow = showId === image.id

  const actionsCls = ['image__actions', actionsDisplay, actionsPlacement]

  const actionCls = ['image__action', actionsSize, actionsBackground]

  const imageCls: Array<string> = [position]

  if (className) {
    imageCls.push(className)
  }

  if (hoverShowCheckbox) {
    imageCls.push('hover-show-checkbox')
  }

  if (checked) {
    imageCls.push('checked')
  }

  const imgProps = {
    [lazyload ? 'data-src' : 'src']: isFormShow ? src : spliceWebPImageUrl(src, isWebPSupported),
    className: `default ${lazyload ? 'lazyload' : ''}`,
    alt,
  }
  let isError = false

  const imgError = (e) => {
    if (!isError) {
      const img = e.target
      img.src = spliceWebPImageUrl(srcTx, isWebPSupported)
      isError = true
    }
  }

  let child = <img {...imgProps} onClick={() => !id && onClick()} onLoad={onLoad} onError={imgError} />

  let link =
    href ||
    `/${
      CONTENT_TYPES.concat(
        { text: 'IP', key: ContentType.Ip, router: 'ip' },
        { text: 'nav.dam', key: 5, router: 'dam' }
      ).find((_) => _.key === contentType).router
    }/show/${id}`

  if (searchStatistic) {
    link += `?${qs.stringify(searchStatistic)}`
  }

  child = id ? (
    <a href={link} target={target} onClick={() => onClick(id)}>
      {child}
    </a>
  ) : (
    child
  )

  child = tooltip ? (
    <Tooltip
      hasArrow={false}
      title={tooltip.title}
      placement={tooltip.placement || 'right'}
      overlayStyle={tooltip.overlayStyle}
      modifiers={{
        preventOverflow: {
          boundariesElement: tooltip.boundariesElement,
          priority: ['top'], // 依次阻止溢出
        },
      }}
    >
      {child}
    </Tooltip>
  ) : (
    child
  )

  return (
    <ImageContainer isCoverImage={isCoverImage} className={imageCls.join(' ')} {...rest}>
      {children || child}
      {contentType === ContentType.Font && <FontBg className={position} />}

      <WithEventCheckBox
        checked={checked}
        index={index}
        onChange={(isChecked) => onCheckChange(checkedId, isChecked, index, contentType)}
      />
      {isCoverImage ? (
        <span className="no_cover">
          <FormattedMessage id="page.editorial.no-cover-images">{(msg) => msg}</FormattedMessage>
        </span>
      ) : null}
      {actions ? (
        <div className={actionsCls.join(' ')}>
          {hasTitle ? describe : null}
          <div className="icon-group">
            {actions.map((action, i) => (
              <span className={actionCls.join(' ')} key={`image__action-${i}`}>
                {action}
              </span>
            ))}
          </div>
        </div>
      ) : null}

      {tag ? (
        tag === 'buy' ? (
          <a
            href={`/account/purchased?able_ids=${id}&type=${contentType}`}
            target="_blank"
            className="icon icon_buy"
            rel="noreferrer"
          >
            <Icon glyph={tag} size={48} />
          </a>
        ) : (
          <Icon glyph={tag} className={`icon ${tag}`} size={tagSize} />
        )
      ) : null}

      {isAigc && (
        <div className="aiTag">
          <Tooltip
            placement="bottom-start"
            overlayStyle={{ boxShadow: 'none', zIndex: 1050, background: '#333333CC' }}
            title={
              <div style={{ color: '#fff', width: '246px' }}>
                本内容为AIGC，由创作者使用第三方人工智能技术和工具辅助创作而成的内容，包含图形、音频、视频等形式。
              </div>
            }
          >
            <Icon glyph="aigc-image-icon" width={34} height={22} />
          </Tooltip>
        </div>
      )}
    </ImageContainer>
  )
})

export default Image

const LayoutContainer = styled.div`
  position: relative;
  outline: 1px solid;
  display: flex;
  justify-content: center;

  &.show_checkbox,
  &.checked {
    .check-item {
      visibility: visible;
    }
  }

  &.image-cover-default {
    position: relative;
    text-align: center;

    img {
      width: 96px;
      height: 96px;
      top: 50%;
      left: 50%;
      margin-top: -48px;
      margin-left: -48px;
      position: absolute;
    }

    span {
      position: relative;
      top: 175px;
      font-size: 14px;
      color: ${palette.gray};
    }
  }

  > .image__mask {
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.3));
    width: 100%;
    color: ${palette.white};
    font-size: 12px;
    text-align: center;
  }

  a.center {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
`

export function LayoutImage({
  className,
  index,
  aspect,
  layoutHeight = 256,
  layoutWidth = 256,
  alignItems = 'center',
  borderColor = palette.daisy,
  margin,
  src,
  srcTx,
  pixelCrop,
  mask,
  showCheckbox,
  onClick,
  onCheckChange,
  checked,
  checkedId,
  id,
  contentType,
  actionsBackground,
  tooltip,
  isCoverImage,
  searchStatistic,
  children,
  isAigc,
  ...rest
}: ImageProps & {
  /** 资源ID 非唯一标识符 仅用于跳转详情页 */
  id?: string
  /** 唯一标识符 */
  layoutHeight?: any
  layoutWidth?: any
  borderColor?: string
  alignItems?: 'center' | 'top' | 'bottom'
  aspect?: number
  pixelCrop?: ReactCrop.PixelCrop
  actionsBackground?: 'white' | 'black'
  className?: string
  /** 是否一直显示checkbox --- 个人中心使用 */
  showCheckbox?: boolean
  /** 是否封面图片已经下架 */
  isCoverImage?: number | string
  searchStatistic?: PLUS.SearchStatistic
  children?: React.ReactChild
}) {
  const [naturalAspect, setNaturalAspect] = useState(aspect)
  const [croppedImgUrl, setCroppedImgUrl] = useState(src)
  const [croppedImgUrlTx, setCroppedImgUrlTx] = useState(srcTx)

  useEffect(() => {
    // 不使用 naturalAspect 是因为在更新后naturalAspect会有值
    if (!aspect && !children) {
      loadImage(src)
        .then((img: HTMLImageElement) => {
          if (pixelCrop && img) {
            getCroppedImg(img, pixelCrop).then((url: string) => {
              setNaturalAspect(pixelCrop.width / pixelCrop.height)
              setCroppedImgUrl(url)
            })
          }

          setNaturalAspect(img.naturalWidth / img.naturalHeight)
          setCroppedImgUrl(src)
        })
        .catch(console.error)
    } else {
      setNaturalAspect(aspect)
      setCroppedImgUrl(src)
    }
  }, [src])

  useEffect(() => {
    // 不使用 naturalAspect 是因为在更新后naturalAspect会有值
    if (!aspect && !children) {
      loadImage(srcTx)
        .then((img: HTMLImageElement) => {
          if (pixelCrop && img) {
            getCroppedImg(img, pixelCrop).then((url: string) => {
              setNaturalAspect(pixelCrop.width / pixelCrop.height)
              setCroppedImgUrlTx(url)
            })
          }

          setNaturalAspect(img.naturalWidth / img.naturalHeight)
          setCroppedImgUrlTx(srcTx)
        })
        .catch(console.error)
    } else {
      setNaturalAspect(aspect)
      setCroppedImgUrlTx(srcTx)
    }
  }, [srcTx])

  if (!naturalAspect) {
    return null
  }

  let imageStyle = null
  if (naturalAspect >= 1) {
    imageStyle = {
      width: layoutWidth,
      height: Math.floor(layoutWidth / naturalAspect) - 2,
    }
  } else {
    imageStyle = {
      width: Math.floor(layoutHeight * naturalAspect),
      height: layoutHeight,
    }
  }

  const cls: Array<string> = [className]

  if (showCheckbox) {
    cls.push('show_checkbox')
  }
  if (isCoverImage === 1) {
    cls.push('image-cover-default')
  }

  return (
    <LayoutContainer
      className={cls.join(' ')}
      style={{
        width: layoutWidth,
        height: layoutHeight,
        alignItems,
        outlineColor: borderColor,
        margin,
      }}
    >
      <Image
        index={index}
        actionsBackground={actionsBackground}
        key={croppedImgUrl}
        src={croppedImgUrl}
        srcTx={croppedImgUrlTx}
        style={imageStyle}
        lazyload={!pixelCrop}
        contentType={contentType}
        id={id}
        checkedId={checkedId}
        checked={checked}
        onClick={onClick}
        onCheckChange={onCheckChange}
        tooltip={tooltip}
        isCoverImage={isCoverImage}
        searchStatistic={searchStatistic}
        {...rest}
        isAigc={isAigc}
      >
        {children}
      </Image>
      {mask ? (
        <div className="image__mask" style={{ bottom: (layoutHeight - imageStyle.height) / 2 }}>
          {mask}
        </div>
      ) : null}
    </LayoutContainer>
  )
}
